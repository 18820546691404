import React, { createContext, useEffect, useState } from 'react';
import {createUserWithEmailAndPassword, getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup, signOut, sendPasswordResetEmail, signInWithEmailLink} from 'firebase/auth';
import app from '../firebase/firebase.config';
import { getDatabase, ref, get, orderByChild, query, onValue } from "firebase/database";

export const AuthContext = createContext();

const auth = getAuth(app);

const UserContext = ({children}) => {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [permission, setPermission] = useState('user');
    const [userList, setUserList] = useState([]);
    const [userInfo, setUserInfo] = useState();
    const googleProvider = new GoogleAuthProvider();

    const createUser = (email, password, name) =>{
        return createUserWithEmailAndPassword(auth, email, password, name);
    }

    const getUsers = async () => {
        // Reference to your collection
        const db = getDatabase();
        const userRef = ref(db, 'users');
        const tempSnapshot = await get(ref(db, "TempUsers/"))
        const tempUsers = [];
        if (tempSnapshot.exists()) {
            const tempUser = tempSnapshot.val();
            for (const tempId in tempUser) {
                const tempUserInfo = tempUser[tempId];
                tempUsers.push({uid:tempId, ...tempUserInfo, isTemp:true})
            }
        }
        
        const users = [];
        const snapshot = await get(userRef)
        if (snapshot.exists()) {
            snapshot.forEach((childSnapshot) => {
                users.push({uid:childSnapshot.key, ...childSnapshot.val()});
            });
            const totalusers = [...tempUsers,  ...users]
            const sortedUsers = totalusers.sort((a, b) => {
                const surnameA = a.surname.toUpperCase();
                const surnameB = b.surname.toUpperCase();
            
                if (surnameA < surnameB) {
                    return -1;
                } else if (surnameA > surnameB) {
                    return 1;
                } else {
                    return 0;
                }
            });       
            setUserList(sortedUsers);
        }
    }

    const signIn = (email, password)=>{
        return signInWithEmailAndPassword(auth, email, password);
    }

    const signInWithEmailLink = (email) => {
        return signInWithEmailLink(auth, email);
    }

    const resetPassword = (email) => {
        return sendPasswordResetEmail(auth, email)
    }

    const signInWithGoogle = () =>{
        return signInWithPopup(auth, googleProvider)
    }

    const logOut = () =>{
        localStorage.removeItem('userData');
        localStorage.removeItem('userPermission');
        setPermission(null);
        setUser(null);
        return signOut(auth);
    }

    useEffect(() =>{
        setLoading(true);
        const db = getDatabase();
        const userRef = ref(db, 'users');
        const tempUserRef = ref(db, "TempUsers");

        // Combine temp users and permanent users in real time
        const unsubscribeUserDb = onValue(tempUserRef, (tempSnapshot) => {
            const tempUsers = [];
            if (tempSnapshot.exists()) {
                const tempUser = tempSnapshot.val();
                for (const tempId in tempUser) {
                    const tempUserInfo = tempUser[tempId];
                    tempUsers.push({ uid: tempId, ...tempUserInfo, isTemp: true });
                }
            }

            onValue(userRef, (userSnapshot) => {
                const users = [];
                if (userSnapshot.exists()) {
                    userSnapshot.forEach((childSnapshot) => {
                        users.push({ uid: childSnapshot.key, ...childSnapshot.val() });
                    });
                }
                
                // Combine and sort users here
                const totalUsers = [...tempUsers, ...users];
                const sortedUsers = totalUsers.sort((a, b) => {
                    const surnameA = a.surname.toUpperCase();
                    const surnameB = b.surname.toUpperCase();
                    if (surnameA < surnameB) {
                        return -1;
                    } else if (surnameA > surnameB) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                setUserList(sortedUsers); // Update state
            });
        });

        // getUsers();
        const user = JSON.parse(localStorage.getItem('userData'));
        const permission = JSON.parse(localStorage.getItem('userPermission'));
        // Set the context state with the retrieved user data and permission
        setUser(user);
        setPermission(permission);
        setLoading(false);
        const unsubscribe = onAuthStateChanged(auth, currentUser =>{
            localStorage.setItem('userData', JSON.stringify(currentUser));
            const db = getDatabase();
            get(ref(db, "users/" + currentUser?.uid)).then((userInfo)=>{
                // setUser({...currentUser, emailVerified:userInfo.val()?.verified ?? false});  
                if(currentUser && userInfo.val()?.verified) { 
                    setUser(currentUser);
                    setLoading(false);
                }
                if (userInfo.val()?.permission=='admin')
                {
                    setPermission('admin');
                    // localStorage.setItem('userData', JSON.stringify({...currentUser, emailVerified:userInfo.val()?.verified ?? false}));
                    localStorage.setItem('userPermission', JSON.stringify('admin'));
                }
            })
            .catch((error)=>{
                console.error('error',error);
            })
        })
        return () =>{
            unsubscribeUserDb();
            unsubscribe();
        }
    }, [])

    useEffect(()=>{
        const db = getDatabase();
        get(ref(db, "users/" + user?.uid)).then((userInfo)=>{
        setUserInfo(userInfo.val());
        })
        .catch((error)=>{
        console.error(error)
        });
    },[user, loading])

    const authInfo = {user, userInfo, getUsers, userList, loading, permission, createUser, signIn, signInWithEmailLink, resetPassword, logOut, signInWithGoogle, setPermission}

    return (
        <AuthContext.Provider value={authInfo}>
            {children}
        </AuthContext.Provider>
    );
};

export default UserContext;