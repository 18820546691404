import React, { useEffect, useState } from "react";
import "aos/dist/aos.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../index.css";

import Footer from "../components/Footer";
import heroBackground from '../images/bg_1.png';
import MailchimpFormNews from "../components/MailchimpFormContainerNews";
import MobileMenu from "../components/MobileMenu";
import ResponsiveHeader from "../components/ResponsiveHeader";
import ReactPaginate from "react-paginate";

// import SimpleHeader from "../components/SimpleHeader";
// import LazyShow from "../components/LazyShow";
// import MobileHeader from "../components/MobileHeader";

const itemsPerPage = 10;


function Newsletter() {

  const [ direction, setDirection ] = useState('desc')
  const [ currentPage, setCurrentPage ] = useState(0);
  const [ keyword, setKeyword ] = useState('')
  const [ newsletters, setNewsletters ] = useState<any>()
  const [ filteredItems, setFilteredItems ] = useState<any[]>([])
  const [ displayedNews, setDisplayedNews ] = useState(10);

  const CONTENTFUL_SPACE_ID = "qitjy5hyl5up"
  const CONTENTFUL_ACCESS_TOKEN = "TKo1zCQL_v1NQmGcRQXQVqayvDSqukrdCeRlbpEedyM"

  const contentfulEndpointNewsletter = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=newsletter`;

  const handleLoadMore = () => {
    const currentNews = filteredItems?.length ?? 0;
    if (displayedNews+10>currentNews) {
      setDisplayedNews(currentNews);
    } else {
      setDisplayedNews(displayedNews+10);
    }
  }

  const handleChange = (e:any) => {
    setKeyword(e.target.value)
    setFilteredItems(newsletters.items.filter((item:any)=>{
      return (item.fields.title.toUpperCase().includes(e.target.value.toUpperCase()))
    }))
  }

  const handlePageClick = (event:any) => {
    setCurrentPage(event.selected);
    window.scrollTo({
      top: 0,
    })
  }

  const sortData = (direction:any) => {
    const newFilteredItems = [...filteredItems].sort(
      function(a:any,b:any)
      {
        if (direction=='desc') 
          return b.fields.date.localeCompare(a.fields.date); 
        else 
          return a.fields.date.localeCompare(b.fields.date);
      }
    )
    setDirection(direction)
    setFilteredItems(newFilteredItems) 
  }
  
  useEffect(()=>{
    (async () => {
      try {
        const res = await fetch(contentfulEndpointNewsletter);
        const data = await res.json();
        console.log()
        setNewsletters(data)
        const newFilteredItems = [...data.items].sort(
          function(a:any,b:any)
          {
            if (direction=='desc') 
              return b.fields.date.localeCompare(a.fields.date); 
            else 
              return a.fields.date.localeCompare(b.fields.date);
          }
        )
        setFilteredItems(newFilteredItems)
      } catch (error) {
        console.log(error);
      }
    })();
  },[])

  const pageCount = Math.ceil(filteredItems?.length/itemsPerPage);
  const startIndex = itemsPerPage * currentPage;
  const endIndex = startIndex + itemsPerPage;
  const currentFilteredItems = filteredItems.slice(startIndex, endIndex);

  return (
    <>
      <ResponsiveHeader backgroundImage={heroBackground} title="Newsletter" />
      <section>
        <div className="pb-[100px] md:pb-[250px]">
          <div className="grid grid-cols-1 md:grid-cols-3">
            <div className="col-span-1 pt-10 pl-10 hidden md:block">
                <h1 className="text-[#0c2957] text-[2.3rem] w-1/2 font-bold mb-10 ms-8">Archivio Newsletter</h1>
                <div className="bg-[#e0f9fd] w-full pt-12 pb-10 pl-10 pr-16">
                    <h1 className="w-[200px] text-3xl text-[#0c2957] font-bold mb-6">
                        Iscriviti alla newsletter
                    </h1>
                    <p className="text-lg font-semibold">
                        Iscriviti per ricevere periodicamente notizie e aggiornamenti sul mondo 
                        di Italy HLG, incluse le nostre iniziative in anteprima, i nostri eventi 
                        e quello che nella nostra industria ci ispira e ci guida. Iscrivendoti 
                        accetti <span>questi termini.</span>
                    </p>
                    <MailchimpFormNews/>
                </div>
            </div>
            <div className="col-span-2 z-10  md:mt-[-120px]">
              <div className=" z-50 bg-white pt-[60px] md:pt-[100px] ">
                <div className="flex flex-col items-end gap-20 md:grid md:grid-cols-3 md:gap-4 px-[60px] md:pl-[120px]">
                  <div className="col-span-2 flex mb-0 w-full">
                    <input onChange={handleChange} type="text" placeholder="Cerca.." className="border border-black text-lg p-3 w-[30rem]" />
                  </div>
                  <div className="col-span-1 flex flex-col mt-auto">
                    <p onClick={()=>{if(direction=='desc') sortData('asc'); else sortData('desc');}} className="text-sm hover:font-bold cursor-pointer">Ordina per data &nbsp;<span className="text-[#00a695] font-bold">{`${direction=='desc'?'⬇':'⬆'}`}</span></p>
                  </div>
                </div>
                
                <div className="md:hidden">
                  {filteredItems.slice(0,displayedNews).map((item:any, idx:number)=>{
                      return(
                        <>
                          <div key={idx} className={`pb-10 md:mb-10 border-[#aaaaaa] ${idx==displayedNews-1 ? '' : 'border-b-[1px]'} md:mr-64 mx-[60px] md:ml-[120px]`}>
                            <h1 className="text-[1.5rem] font-bold text-[#0c2957] leading-[2.5rem] mt-12 mb-6">{item.fields.title}</h1>
                            <p className="text-lg font-[500] text-[#444444]  mb-4">Inviata il <span className="text-[#00a695] text-sm font-bold">{item.fields.date}</span></p>                    
                            <a target="_blank" href={item?.fields.link??newsletters?.includes.Asset.filter((asset:any)=>{return asset.sys.id==item.fields.pdf.sys.id})[0].fields.file.url} className="text-[1.3rem] text-[#00a695] font-semibold hover:underline cursor-pointer underline">{item?.fields?.link?'View':'Download'}</a>
                          </div>
                          {
                            idx == 2 &&
                            <div className="col-span-1 pt-10 md:hidden">
                              <div className="bg-[#e0f9fd] w-full pt-12 pb-10 pl-10 pr-16">
                                  <h1 className="text-2xl md:text-3xl text-[#0c2957] font-bold mb-6">
                                      Iscriviti alla newsletter
                                  </h1>
                                  <p className="text-[15px] md:text-lg font-semibold">
                                      Iscriviti per ricevere periodicamente notizie e aggiornamenti sul mondo 
                                      di Italy HLG, incluse le nostre iniziative in anteprima, i nostri eventi 
                                      e quello che nella nostra industria ci ispira e ci guida. Iscrivendoti 
                                      accetti <span>questi termini.</span>
                                  </p>
                                  <MailchimpFormNews/>
                              </div>
                          </div>
                          }
                        </>

                      )
                    })     
                  } 
                </div>
                <div className="hidden md:block">
                  {currentFilteredItems.map((item:any, idx:number)=>{
                      return(
                        <>
                          <div key={idx} className={`pb-10 md:mb-10 border-[#aaaaaa] ${idx==currentFilteredItems.length-1 ? '' : 'border-b-[1px]'} md:mr-64 mx-[60px] md:ml-[120px]`}>
                            <h1 className="text-[1.5rem] font-bold text-[#0c2957] leading-[2.5rem] mt-12 mb-6">{item.fields.title}</h1>
                            <p className="text-lg font-[500] text-[#444444]  mb-4">Inviata il <span className="text-[#00a695] text-sm font-bold">{item.fields.date}</span></p>                    
                            <a target="_blank" href={item?.fields.link??newsletters?.includes.Asset.filter((asset:any)=>{return asset.sys.id==item.fields.pdf.sys.id})[0].fields.file.url} className="text-[1.3rem] text-[#00a695] font-semibold hover:underline cursor-pointer underline">{item?.fields?.link?'View':'Download'}</a>
                          </div>
                          {
                            idx == 2 &&
                            <div className="col-span-1 pt-10 md:hidden">
                              <div className="bg-[#e0f9fd] w-full pt-12 pb-10 pl-10 pr-16">
                                  <h1 className="text-2xl md:text-3xl text-[#0c2957] font-bold mb-6">
                                      Iscriviti alla newsletter
                                  </h1>
                                  <p className="text-[15px] md:text-lg font-semibold">
                                      Iscriviti per ricevere periodicamente notizie e aggiornamenti sul mondo 
                                      di Italy HLG, incluse le nostre iniziative in anteprima, i nostri eventi 
                                      e quello che nella nostra industria ci ispira e ci guida. Iscrivendoti 
                                      accetti <span>questi termini.</span>
                                  </p>
                                  <MailchimpFormNews/>
                              </div>
                          </div>
                          }
                        </>

                      )
                    })     
                  } 
                </div>
                <div onClick={handleLoadMore} className="bg-[#b0d8ff] hover:cursor-pointer py-4 text-white md:hidden mx-[60px] mt-16 text-center">
                  LOAD MORE
                </div>
                <ReactPaginate
                    initialPage={0}
                    breakLabel="..."
                    containerClassName="hidden ml-[120px] md:flex gap-4 justify-left items-center text-xl font-semibold text-gray-600"
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={itemsPerPage}
                    pageCount={pageCount}
                    previousLabel="<"
                    pageClassName="flex hover:font-bold w-[30px] h-[30px] pb-[3px] rounded-full items-center justify-center text-center bg-[#ffffff]"
                    pageLinkClassName="w-full"
                    previousClassName="hover:bg-gray-400 w-[25px] text-center"
                    nextClassName="hover:bg-gray-400 w-[25px] text-center"
                    previousLinkClassName="w-full"
                    nextLinkClassName="w-full"
                    activeClassName="pagniation-active-bg text-white"
                    renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
        <MobileMenu/>
      </section>
      <Footer></Footer>
    </>
  );
}

export default Newsletter;
